<template>
  <div>
    <div class="flex-between operation padding-bottom-20">
      <div>
        <el-button
          type="primary"
          @click="handleAddRelation"
          style="margin-right: 14px"
          >添加关联</el-button
        >
        <el-button class="margin-right-twentyFour" @click="handlePageChange"
          >刷新数据</el-button
        >
        <el-input
          placeholder="账户名"
          v-model="params.targetName"
          clearable
          class="input-with-select margin-right-twentyFour"
          @keydown.native.enter="handlePageChange(1)"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handlePageChange(1)"
          ></el-button>
        </el-input>
        <el-select
          class="margin-right-twentyFour"
          v-model="params.accountChannelId"
          filterable
          placeholder="公众号"
          clearable
          @change="handlePageChange"
        >
          <el-option
            v-for="item in officialList"
            :key="item.id"
            :value="item.id"
            :label="item.channelName"
          >
            <span style="float: left">{{ item.channelName }}</span>
            <span
              :style="
                item.status
                  ? 'margin-left: 10px;color:#00bf8a'
                  : 'margin-left: 10px;color:#fd5d5a'
              "
              >{{ item.status ? "绑定" : "解绑" }}</span
            >
            <span style="float: right; margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span
            >
          </el-option>
        </el-select>
        <el-select
          v-model="params.booksId"
          filterable
          remote
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
          @change="handlePageChange"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-tag style="color: #72767b; font-size: 12px; margin: 0 15px"
          >共{{ tableData.length }}条</el-tag
        >
      </div>
      <el-button
        style="margin-left: auto"
        @click="handleRefresh"
        :loading="loadingFresh"
        >同步数据</el-button
      >
    </div>

    <el-table
      v-loading="loading"
      :data="tableData"
      height="calc(100vh - 150px)"
      style="width: 100%"
    >
      <el-table-column prop="id" label="ID" width="100"> </el-table-column>
      <el-table-column prop="targetName" min-width="120" label="账户名">
      </el-table-column>
      <el-table-column prop="channelName" min-width="160" label="公众号">
      </el-table-column>
      <el-table-column min-width="120" prop="bookName" label="书籍">
      </el-table-column>
      <el-table-column min-width="120" prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.status === 1"
            @click="handleUnbind(scope.row.marketingId)"
            style="cursor: pointer"
            >已绑定</el-tag
          >
          <el-tag type="warning" v-else-if="scope.row.status === 0"
            >未接入</el-tag
          >
          <el-tag type="danger" v-else-if="scope.row.status === 2"
            >已解绑</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px">
        <template slot-scope="scope">
          <el-button
            class="button-small"
            type="primary"
            @click="handleAddRelation(scope.row)"
            >{{ scope.row.status === 1 ? "修改" : "添加" }}关联</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加关联 -->
    <el-drawer
      :visible.sync="showRelation"
      size="720px"
      title="添加关联"
      :append-to-body="true"
    >
      <el-form label-width="130px">
        <el-form-item label="账户">
          <el-select
            class="margin-right-twentyFour"
            style="width: 500px"
            v-model="chooseAccountList"
            multiple
            filterable
            :disabled="isSingle"
            placeholder="选择账户"
          >
            <el-option
              v-for="item in tableData"
              :key="item.id"
              :value="item.id"
              :label="item.targetName"
            >
              <span style="float: left">{{ item.targetName }}</span>
              <span
                :style="
                  item.status
                    ? 'margin-left: 10px;color:#00bf8a'
                    : 'margin-left: 10px;color:#fd5d5a'
                "
                >{{ item.status ? "绑定" : "解绑" }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公众号">
          <el-select
            class="margin-right-twentyFour"
            style="width: 500px"
            v-model="relationForm.accountChannelId"
            filterable
            placeholder="公众号"
          >
            <el-option
              v-for="item in officialList.filter(item=>item.status !== 0)"
              :key="item.id"
              :value="item.id"
              :label="item.channelName"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span
                :style="
                  item.status
                    ? 'margin-left: 10px;color:#00bf8a'
                    : 'margin-left: 10px;color:#fd5d5a'
                "
                >{{ item.status ? "绑定" : "未绑定" }}</span
              >
              <span style="float: right; margin-left: 15px; font-size: 13px"
                >{{ item.platformStr }} - {{ item.uname }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="书籍名称">
          <el-select
            v-model="relationForm.booksId"
            filterable
            remote
            clearable
            @clear="getBook"
            style="width: 500px"
            reserve-keyword
            placeholder="书籍名称"
            :remote-method="getBook"
          >
            <el-option
              v-for="item in bookList"
              :key="item.id"
              :label="item.bookName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="showRelation = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="loadingButton"
          @click="handleSubmitRelation"
          >确 定</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getBaiduApiChannel,
  unbindAd,
  getBookList,
  addBaiduApiChannelRelation,
  refreshBaiduApiChannelRelation
} from '@/api/account'
export default {
  props: ['id', 'isShow'],
  data () {
    return {
      tableData: [],
      loading: false,
      params: {},
      bookList: [],
      showRelation: false,
      currentData: {},
      loadingButton: false,
      relationForm: {},
      loadingFresh: false,
      accountList: [],
      chooseAccountList: [],
      isSingle: false
    }
  },
  watch: {
    isShow: function (newValue) {
      if (newValue) {
        this.loading = true
        this.handlePageChange()
        this.getBook()
      }
    }
  },
  computed: {
    ...mapGetters(['officialList'])
  },
  methods: {
    // 获取书籍列表
    getBook (bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName
      }).then((res) => {
        this.bookList = res.list
      })
    },
    // 获取书籍列表by id
    getBookById (booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds
      }).then((res) => {
        this.bookList = res.list
      })
    },
    handleUnbind (id) {
      this.$confirm('确定要解绑?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          unbindAd(id).then((res) => {
            this.$message.success('解绑成功')
            this.handlePageChange()
          })
        })
        .catch(() => {})
    },
    handleSubmitRelation () {
      if (!this.relationForm.accountChannelId) {
        return this.$message.error('请选择公众号')
      }
      if (!this.relationForm.booksId) {
        return this.$message.error('请选择书籍')
      }
      if (this.chooseAccountList.length === 0) {
        return this.$message.error('请选择关联账户')
      }
      const tmpParams = this.chooseAccountList.map((item) => {
        return {
          id: item,
          accountChannelId: this.relationForm.accountChannelId,
          booksId: this.relationForm.booksId,
          marketingId:
            this.tableData.find((item2) => {
              return item2.id === item
            }).marketingId || null
        }
      })
      this.loadingButton = true
      addBaiduApiChannelRelation(tmpParams)
        .then((res) => {
          this.$message.success('关联成功')
          this.showRelation = false
          this.handlePageChange()
        })
        .finally(() => {
          this.loadingButton = false
        })
    },
    handleAddRelation (data) {
      this.currentData = data
      this.relationForm = {}
      this.chooseAccountList = []
      if (this.currentData.status !== 0) {
        this.relationForm = {
          accountChannelId: data.accountChannelId,
          booksId: data.booksId,
          marketingId: data.marketingId
        }
        this.getBookById(data.booksId)
      } else {
        this.getBook()
      }
      if (data.id) {
        this.chooseAccountList = [data.id]
        this.isSingle = true
      } else {
        this.isSingle = false
      }
      this.showRelation = true
    },
    handleRefresh () {
      this.loadingFresh = true
      refreshBaiduApiChannelRelation(this.id)
        .then((res) => {
          this.$message.success('已同步')
          this.handlePageChange()
        })
        .finally(() => {
          this.loadingFresh = false
        })
    },
    handlePageChange () {
      this.loading = true
      getBaiduApiChannel({
        id: this.id,
        targetName: this.params.targetName,
        accountChannelId: this.params.accountChannelId,
        booksId: this.params.booksId
      })
        .then((res) => {
          this.tableData = res
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.loading = true
    this.handlePageChange()
    this.getBook()
  }
}
</script>

<style lang="scss" scoped>
.input-with-select {
  width: 200px;
}
.part {
  background: #fff;
  // padding: 0 15px;
}
.operation {
  background: #fff;
  padding: 0 10px;
  justify-content: space-between !important;
}
/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}

.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
</style>
